import React, { useState } from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';

import Form from '../../components/Form';
import Button from '../../components/Button';
import RsvpForm from '../../components/rsvpForm';
import AngleRight from '../../images/svg/angle-right-solid.svg';

const RsvpFormPage = ({ location }) => {
  const [party, setParty] = useState(() => (location.state ? location.state.party : []));
  const id = location.state ? location.state.id : 0;

  const [isLoading, setLoading] = useState(false);
  const [hasSubmitted, setSubmitted] = useState(false);
  const [isAttending, setAttending] = useState(false);

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    firebase
      .firestore()
      .collection('rsvp-fake')
      .doc(id)
      .set({
        hasResponded: true,
        party: party,
        timestamp: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        party.forEach(member => {
          if (member.status === 'attending') {
            setAttending(true);
          }
        });
        setLoading(false);
        setSubmitted(true);
      })
      .catch(error => {
        alert(error);
      })
      .then(() => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'rsvpForm', details: JSON.stringify(party) }),
        }).catch(error => alert(error));
      });
  }

  return (
    <>
      {!hasSubmitted ? (
        <section>
          {party && (
            <Form onSubmit={handleSubmit} netlify={true} name='rsvpForm'>
              <input type='hidden' name='form-name' value='contact' />
              <input type='hidden' name='details' value='' />
              {party.map((member, index) => (
                <RsvpForm
                  member={member}
                  key={member.id}
                  index={index}
                  party={party}
                  setParty={setParty}
                />
              ))}
              <Button isLoading={isLoading}> {isLoading ? 'Submitting...' : 'Submit RSVP'}</Button>
            </Form>
          )}
          <BackLink to='/rsvp'>
            <StyledAngleRight />
            Search for a different party
          </BackLink>
        </section>
      ) : (
        <Confirmation>
          <h1>Thank you!</h1>
          {isAttending ? (
            <>
              <p>We can't wait to celebrate with you!</p>
              <p>
                In the meantime check out our <Link to='/details'>Wedding Details</Link>.
              </p>
            </>
          ) : (
            <>
              <p>We'll miss you and can't wait to see you again another time!</p>
            </>
          )}
        </Confirmation>
      )}
      <Disclaimer>
        If you have any issues with this form or aren’t seeing your correct names/group, reach out
        to Max or Michael.
      </Disclaimer>
    </>
  );
};

const Disclaimer = styled.p`
  font-size: 14px;
  margin-top: 40px;
`;

const BackLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  font-weight: 500;
`;

const StyledAngleRight = styled(AngleRight)`
  transform: rotate(180deg);
  height: 20px;
  margin-right: 4px;
`;

const Confirmation = styled.section`
  text-align: center;
`;

export default RsvpFormPage;
