import React from 'react';
import styled from 'styled-components';

const Form = ({ children, onSubmit, netlify, name }) => (
  <StyledForm onSubmit={onSubmit} data-netlify={netlify} name={name}>
    {children}
  </StyledForm>
);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;
export default Form;
