import React from 'react';
import styled from 'styled-components';

import TextInput from './TextInput';

const RsvpForm = ({ member, index, party, setParty }) => {
  function handleRadio(e) {
    const index = e.target.dataset.index;
    const update = [...party];
    update[index].status = e.target.value;
    setParty([...update]);
  }

  function handleCheck(e) {
    const index = e.target.dataset.index;
    const update = [...party];
    update[index].dietary = e.target.checked;
    setParty([...update]);
  }

  function handleArea(e) {
    const index = e.target.dataset.index;
    const update = [...party];
    update[index].dietaryDetails = e.target.value;
    setParty([...update]);
  }

  function handlePlusOneName(value) {
    const update = [...party];
    update.forEach(member => member.plusOne && (member.name = value));
    setParty([...update]);
  }
  return (
    <RsvpWrapper>
      {member.plusOne ? (
        <>
          <h2>Plus 1</h2>
          <p>If you're bringing a guest, let us know.</p>
        </>
      ) : (
        <h2>{member.name}</h2>
      )}
      <RadioWrapper required>
        <RadioInput
          type='radio'
          id={`${member.id}-attending`}
          name={`${member.id}-rsvp`}
          value='attending'
          checked={member.status === 'attending'}
          data-index={index}
          onChange={handleRadio}
          required
        />
        <RadioLabel htmlFor={`${member.id}-attending`}>Attending</RadioLabel>
        <RadioInput
          type='radio'
          id={`${member.id}-notAttending`}
          name={`${member.id}-rsvp`}
          value='notAttending'
          data-index={index}
          checked={member.status === 'notAttending'}
          onChange={handleRadio}
          required
        />
        <RadioLabel htmlFor={`${member.id}-notAttending`}>Not Attending</RadioLabel>
      </RadioWrapper>
      {member.plusOne && member.status === 'attending' ? (
        <TextInput
          name={`${member.id}-name`}
          label='Name'
          state={member.name}
          setState={handlePlusOneName}
        />
      ) : null}
      {member.status === 'attending' ? (
        <CheckLabel htmlFor={`${member.id}-dietary`}>
          <input
            type='checkbox'
            id={`${member.id}-dietary`}
            name={`${member.id}-dietary`}
            checked={member.dietary ? member.dietary : false}
            onChange={handleCheck}
            data-index={index}
          />
          Dietary restrictions? <span>(vegetarian, gluten free, etc.)</span>
        </CheckLabel>
      ) : null}
      {member.dietary && member.status === 'attending' ? (
        <TextArea>
          <label htmlFor={`${member.id}-dietaryDetails`}>Dietary Details</label>
          <textarea
            name={`${member.id}-dietaryDetails`}
            id={`${member.id}-dietaryDetails`}
            value={member.dietaryDetails}
            onChange={handleArea}
            data-index={index}
            placeholder='Let us know the details so we can be sure to accomodate!'
          />
        </TextArea>
      ) : null}
    </RsvpWrapper>
  );
};

const RsvpWrapper = styled.article`
  h2,
  p {
    text-align: center;
    position: relative;
  }
  h2 {
    margin-top: 0;
  }
  &:after {
    content: '';
    display: block;
    margin: 2rem auto;
    width: 80%;
    border-bottom: 1px dashed var(--blue);
  }
`;

const RadioWrapper = styled.fieldset`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
`;
const RadioInput = styled.input`
  opacity: 0;
  position: absolute;
`;

const RadioLabel = styled.label`
  color: var(--blue);
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 16px 24px;
  margin: 0.25rem;
  display: inline-block;
  border: 1px solid var(--blue);
  border-radius: 100px;
  transition: background-color 0.2s, color 0.2s;
  font-weight: 600;
  &:hover {
    background-color: rgba(32, 74, 90, 0.8);
    color: var(--sand);
  }
  input[type='radio']:checked + & {
    background-color: var(--blue);
    color: var(--sand);
  }
  input[type='radio']:focus + & {
    border: 1px solid var(--sand);
    box-shadow: 0px 0px 5px rgba(32, 74, 90, 0.8);
  }
`;

const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 0.25rem;
  font-size: 1.125rem;
  input {
    margin-right: 0.5rem;
  }
  span {
    font-size: 14px;
  }
`;

const TextArea = styled.div`
  margin: 0.25rem;
  margin-top: 0.5rem;
  label {
    font-size: 1.125rem;
    display: inline-block;
  }
  textarea {
    min-height: 6em;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 8px;
  }
  @media (min-width: 600px) {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default RsvpForm;
