import React from 'react';
import styled from 'styled-components';

const Button = ({ children, onClick, isLoading }) => (
  <StyledButton onClick={onClick} isLoading={isLoading} type='submit'>
    {children}
  </StyledButton>
);

const StyledButton = styled.button`
  background-color: ${({ isLoading }) => (isLoading ? 'hsl(197, 48%, 14%)' : 'var(--blue)')};
  color: ${({ isLoading }) => (isLoading ? 'hsl(43, 70%, 86%)' : 'var(--sand)')};
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  text-align: center;
  padding: 12px;
  border: none;
  border-radius: 100px;
  transition: background-color 0.2s, color 0.2s;
  margin: 0;
`;
export default Button;

export { StyledButton };
