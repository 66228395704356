import React from 'react';
import styled from 'styled-components';

const TextInput = ({ name, label, state, setState }) => {
  function handleChange(e) {
    setState(e.target.value);
  }
  return (
    <InputWrapper>
      <label htmlFor={name}>{label ? label : name}</label>
      <input type='text' name={name} value={state} onChange={handleChange}></input>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 18px;
  }
  input {
    padding: 12px;
    line-height: 1.4;
    font-size: 18px;
    margin-bottom: 16px;
  }
`;
export default TextInput;
